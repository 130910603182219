export default function Deal({ data }){
    const numeral = require('numeral');

    const json = JSON.parse(data)
    console.log(json)

    var Amount = numeral(json.Amount).format('0,0.0000')
    Amount = Amount.substring(0, Amount.length-5);
    return(
        <>
            {json.Agent_2 ? (
                <div className="announcesingle">
                    <div className="container-fluid">
                        <div className="row" style={{height: "100vh"}}>
                            <div className="col-md-4">
                                <div className="banner">
                                    <div>
                                        <div className="img" 
                                            style={{
                                                backgroundImage: `url(${json.agent1_img.replace(/ /g, '%20')})`
                                        }}/>
                                        <div className="context text-center mt-2">
                                            <h3>{json.Agent}</h3>
                                        </div>
                                    </div>
                                    {/* <img src="" alt="" /> */}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="h-100 d-flex align-items-center">
                                    <div className="context text-center">
                                        <p className="upper">Deal Type:</p>
                                        {/* <h3>David Semenenko</h3> */}
                                        <p className="sub-title deal-type">{json.DealType}</p>
                                        <hr />
                                        <p className="upper">Developer, Project and Amount:</p>
                                        <h3>{json.Developer} {json.Project}</h3>
                                        <p className="sub-title price">AED {Amount}</p>
                                        <hr />
                                        <p className="upper">Congratulations</p>
                                        <p className="sub-title">{json.Content.replace(/&nbsp;/g, ' ')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="banner">
                                    <div>
                                        <div className="img" 
                                            style={{
                                                backgroundImage: `url(${json.agent2_img.replace(/ /g, '%20')})`
                                        }}/>
                                        <div className="context text-center mt-2">
                                                <h3>{json.Agent_2}</h3>
                                        </div>
                                    </div>
                                    {/* <img src="" alt="" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="announcesingle">
                    <div className="container-fluid">
                    {/* grid-custom */}
                        <div className="row"> 
                            <div className="col-md-6 offset-md-1">
                                <div className="h-100 d-flex align-items-center">
                                    <div className="context">
                                        <p className="upper">Agent and Deal Type:</p>
                                        <h3>{json.Agent}</h3>
                                        <p className="sub-title deal-type">{json.DealType}</p>
                                        <hr />
                                        <p className="upper">Developer, Project and Amount:</p>
                                        <h3>{json.Developer} - {json.Project}</h3>
                                        <p className="sub-title price">AED {Amount}</p>
                                        <hr />
                                        <p className="upper">Congratulations</p>
                                        <p className="sub-title">{json.Content.replace(/&nbsp;/g, ' ')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="banner">
                                    <div className="img" 
                                        style={{
                                            backgroundImage: `url(${json.agent1_img.replace(/ /g, '%20')})`
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}